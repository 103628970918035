import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// import Logo from 'https://upload.wikimedia.org/wikipedia/commons/archive/6/6d/20190910111322%21Volkswagen_logo_2019.svg'

// Define the theme
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Container for left and right sides
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

// Left side styling based on current section
const LeftSide = styled(Box)(({ section, theme }) => ({
  width: '27%',
  borderRight: '1px solid #333',
  overflowY: 'auto',
  padding: '1rem',
  backgroundColor: section === 'about' ? '#FFB200' : section === 'projects' ? 'white' : section === 'team' ? '#B60071' : section === 'education' ? '#283593' : '#00796b',
  color: section === 'about' ? 'rgb(255, 12, 12)' : section === 'projects' ? 'black' : section === 'team' ? 'white' : section === 'education' ? 'white' : 'white',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    borderRight: 'none',
    borderBottom: '1px solid #333',
  },
}));

// Right side styling based on current section
const RightSide = styled(Box)(({ section, theme }) => ({
  width: '77%',
  overflowY: 'auto',
  padding: '1rem',
  backgroundColor: section === 'about' ? '#FFB200' : section === 'projects' ? 'white' : section === 'team' ? '#B60071' : section === 'education' ? '#283593' : '#00796b',
  color: section === 'about' ? 'rgb(255, 12, 12)' : section === 'projects' ? 'black' : section === 'team' ? 'white' : section === 'education' ? 'white' : 'white',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

// Section styling
const Section = styled(Box)({
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  minHeight: '80vh', // Adjusted minHeight for better visibility
});

// Individual sections
const AboutUs = styled(Section)({});
const Projects = styled(Section)({});
const Team = styled(Section)({});
const Education = styled(Section)({});
const Hobbies = styled(Section)({});

// Dummy content for each section
const content = {
  about: { title: 'About Us', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.' },
  projects: [
    { title: 'Project 1', description: 'Detailed description for Project 1. Includes key details and information.', image: 'https://via.placeholder.com/150' },
    { title: 'Project 2', description: 'Detailed description for Project 2. Provides an overview of the objectives and outcomes.', image: 'https://via.placeholder.com/150' },
    { title: 'Project 3', description: 'Detailed description for Project 3. Highlights significant achievements and milestones.', image: 'https://via.placeholder.com/150' },
  ],
  team: ['Alice Johnson', 'Bob Smith', 'Carol Davis', 'David Wilson'],
  education: ['Bachelor of Science in Computer Science', 'Master of Science in Data Science', 'PhD in Artificial Intelligence', 'MBA in Management'],
  hobbies: ['Reading Science Fiction', 'Traveling the World', 'Playing Guitar', 'Cooking Gourmet Meals'],
};

const App = () => {
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const [currentSection, setCurrentSection] = useState('about');
  const [scrollingEnabled, setScrollingEnabled] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollingEnabled) return;

      const rightScrollTop = rightRef.current.scrollTop;
      const sectionHeights = Array.from(rightRef.current.childNodes).map(child => child.offsetHeight);

      if (rightScrollTop < sectionHeights[0]) {
        setCurrentSection('about');
      } else if (rightScrollTop < sectionHeights[0] + sectionHeights[1]) {
        setCurrentSection('projects');
      } else if (rightScrollTop < sectionHeights[0] + sectionHeights[1] + sectionHeights[2]) {
        setCurrentSection('team');
      } else if (rightScrollTop < sectionHeights[0] + sectionHeights[1] + sectionHeights[2] + sectionHeights[3]) {
        setCurrentSection('education');
      } else {
        setCurrentSection('hobbies');
      }
    };

    const handleManualScroll = () => {
      setScrollingEnabled(true);
    };

    rightRef.current.addEventListener('scroll', handleScroll);
    rightRef.current.addEventListener('wheel', handleManualScroll); // Handle mouse wheel scrolling

    return () => {
      rightRef.current.removeEventListener('scroll', handleScroll);
      rightRef.current.removeEventListener('wheel', handleManualScroll);
    };
  }, [scrollingEnabled]);

  useEffect(() => {
    // Handle automatic scrolling for each section
    const scrollToSection = () => {
      if (!scrollingEnabled) return;

      const sectionOffsets = Array.from(rightRef.current.childNodes).map(child => child.offsetTop);
      const currentSectionIndex = ['about', 'projects', 'team', 'education', 'hobbies'].indexOf(currentSection);

      if (currentSectionIndex !== -1) {
        rightRef.current.scrollTo({
          top: sectionOffsets[currentSectionIndex],
          behavior: 'smooth',
        });
      }
    };

    scrollToSection();
  }, [currentSection]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <LeftSide ref={leftRef} section={currentSection}>
          <Box><img src='https://upload.wikimedia.org/wikipedia/commons/archive/6/6d/20190910111322%21Volkswagen_logo_2019.svg' alt='logo' width={40}/></Box>
          {currentSection === 'about' && (
            <Box sx={{ marginTop: '10rem' }}>
              <Typography variant="h4" sx={{ marginBottom: '1rem' }}>{content.about.title}</Typography>
              <Typography sx={{ marginTop: '3rem' }}>{content.about.description}</Typography>
            </Box>
          )}
          {currentSection === 'projects' && (
            <Box sx={{ marginTop: '10rem' }}>
              <Typography variant="h4" sx={{ marginBottom: '1rem' }}>Projects</Typography>
              <Typography sx={{ marginTop: '3rem' }}>This section displays a list of projects with their descriptions and images.</Typography>
            </Box>
          )}
          {currentSection === 'team' && (
            <Box sx={{ marginTop: '10rem' }}>
              <Typography variant="h4" sx={{ marginBottom: '1rem' }}>Team Members</Typography>
              <Typography sx={{ marginTop: '3rem' }}>{content.about.description}</Typography>
            </Box>
          )}
          {currentSection === 'education' && (
            <Box sx={{ marginTop: '10rem' }}>
              <Typography variant="h4" sx={{ marginBottom: '1rem' }}>Education</Typography>
              <Typography sx={{ marginTop: '3rem' }}>{content.about.description}</Typography>
            </Box>
          )}
          {currentSection === 'hobbies' && (
            <Box sx={{ marginTop: '10rem' }}>
              <Typography variant="h4" sx={{ marginBottom: '1rem' }}>Hobbies</Typography>
              <Typography sx={{ marginTop: '3rem' }}>{content.about.description}</Typography>
            </Box>
          )}
        </LeftSide>
        <RightSide ref={rightRef} section={currentSection}>
          <AboutUs>
            <Typography variant="h4">
              <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px', color: 'rgb(255, 12, 12)' }} />
              About Us
            </Typography>
            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</Typography>
          </AboutUs>
          <Projects>
            <Typography variant="h4">
              <WorkIcon style={{ verticalAlign: 'middle', marginRight: '8px', color: 'black' }} />
              Projects
            </Typography>
            <Grid container spacing={2}>
              {content.projects.map((project, index) => (
                <Grid item xs={12} key={index}>
                  <Card style={{ backgroundColor: '#f5f5f5', color: 'black', marginBottom: '1rem' }}>
                    <CardMedia component="img" height="140" image={project.image} alt={project.title} />
                    <CardContent>
                      <Typography variant="h5">{project.title}</Typography>
                      <Typography>{project.description}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Projects>
          <Team>
            <Typography variant="h4">
              <GroupIcon style={{ verticalAlign: 'middle', marginRight: '8px', color: 'white' }} />
              Team Members
            </Typography>
            {content.team.map((member, index) => (
              <Typography key={index} variant="body1">{member}</Typography>
            ))}
          </Team>
          <Education>
            <Typography variant="h4">
              <SchoolIcon style={{ verticalAlign: 'middle', marginRight: '8px', color: 'white' }} />
              Education
            </Typography>
            {content.education.map((item, index) => (
              <Typography key={index} variant="body1">{item}</Typography>
            ))}
          </Education>
          <Hobbies>
            <Typography variant="h4">
              <SportsSoccerIcon style={{ verticalAlign: 'middle', marginRight: '8px', color: 'white' }} />
              Hobbies
            </Typography>
            {content.hobbies.map((item, index) => (
              <Typography key={index} variant="body1">{item}</Typography>
            ))}
          </Hobbies>
        </RightSide>
      </Container>
    </ThemeProvider>
  );
};

export default App;
